<template>
  <div class="wrapCouse">
    <Header/>
    <div class="searchs">
      <div class="searchsWrap">
        <div class="inputSearch" style="width: 990px;margin-top: 0;border: 2px solid #185BE0">
          <img src="https://static.dasdt.cn/images/search.png" alt="">
          <div class="contentInput" style="width: 871px;">
            <el-input v-model="input" placeholder="搜索课程"></el-input>
          </div>
          <div class="submit" @click="records">搜索</div>
        </div>
      </div>
    </div>
    <div style="display: flex;width: 1200px;margin: 0 auto">
      <div class="menuBar">
        <div class="item" v-for="item in classifyList" :key="item.classifyId">
          <div class="title">{{ item.classifyName }}</div>
          <div class="titless" @click="select2(its.classifyId, item.classifyId)" :class="{current: current2 === its.classifyId}" v-for="its in item.childList" :key="its.classifyId"><div class="yuan"></div>{{ its.classifyName }}</div>
        </div>
      </div>
      <div class="courseListWrap">
        <div class="wrapbanners">
          <div class="banner">
            <el-carousel height="237px">
              <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <img :src="item.bgImage.url" alt="" >
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="rightGuangGao">
            <img src="https://static.dasdt.cn/images/banner2.png">
            <img src="https://static.dasdt.cn/images/advertisement4.png">
          </div>
        </div>
        <div class="curriculumList"
             v-loading="loading"
             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="transparent"
        >
          <div class="item" v-for="(item,index) in curriculumList" :key="index" style="height: 295px">
            <curriculumInfo :info="item" :current1="current1" :current2="current2"/>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
              background
              layout="prev, pager, next, total"
              :total="totalCount"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>

      </div>
    </div>

    <Footer/>
    <floatRight/>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import curriculumInfo from "@/views/home/curriculumInfo.vue";
import Footer from "@/components/footer/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import {classify, records} from "@/api/course";
import {pagePCMember} from "@/api/currency";

export default {
  metaInfo: {
    title: '云梯', // set a title
    meta: [   // set meta
      {
        name: '云梯, 人才, 招聘, 数字化, 神马, 服务, 平台, 企业, 推荐, 职位, 岗位',
        content: '云梯, 人才, 招聘, 数字化, 神马, 服务, 平台, 企业, 推荐, 职位, 岗位, 云梯数字化人才服务平台'
      }
    ]
  },
  components: {floatRight, Footer, curriculumInfo, Header},
  data() {
    return {
      loading: true,
      input: '',
      current1: 1,
      current2: 2,
      current3: 3,
      curriculumList: [],
      classifyList: [],
      pagination: {
        number: 1,
        limit: 12
      },
      totalCount: 0,
      bannerList: [],
      bannerList2: [],
    }
  },
  computed: {
    twoClassList() {
      let arr = this.classifyList.filter(item => item.classifyId === this.current1)
      return arr[0]?.childList
    },
    param() {
      return {
        firstClassifyId: this.current1,
        classifyId: this.current2,
        key: this.input,
      }
    }
  },
  watch: {
    twoClassList(newVal) {
      this.current2 = newVal[0]?.classifyId
    },
    param() {
      this.records()
    },
    pagination: {
      handler: function () {
        this.records()
      },
      deep: true
    },
  },
  created() {
    this.pagePCMember()
    this.pagePCMember2()
    this.classify()
  },
  mounted() {
    scrollTo(0, 0)
  },
  methods: {
    async pagePCMember() {
      const {data} = await pagePCMember('study')
      this.bannerList = data
    },
    async pagePCMember2() {
      const {data} = await pagePCMember('certification_training')
      this.bannerList2 = data
    },
    // 获取课程分类
    async classify() {
      const {data} = await classify()
      this.classifyList = data
      await this.records()
    },
    // 课程列表
    async records() {
      const res = await records(this.param, this.pagination)
      console.log(res)
      this.loading = true
      this.curriculumList = res.data
      this.totalCount = res.totalCount
      this.loading = false
    },
    handleCurrentChange(el) {
      this.pagination.number = el
    },
    select(el) {
      this.current1 = el
    },
    select2(el, er) {
      this.current1 = er
      this.current2 = el
    },
    select3(el) {
      this.current3 = el
    },
  }
}
</script>
<style scoped lang="scss">
.wrapCouse {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;
  .menuBar{
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px;
    width: 210px;
    height: 1000px;
    position: relative;
    z-index: 9;
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .current{
      border-radius: 8px;
      background: linear-gradient(to right, #5C95FC, #1E65E7);
      color: #fff !important;

      .yuan{
        background:  #fff !important;
      }
    }
    .titless{
      cursor: pointer;
      height: 36px;
      color: #666666;
      font-size: 14px;
      display: flex;
      align-items: center;
      .yuan{
        margin-left: 12px;
        margin-right: 12px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #666666;
      }
    }
    .title{
      cursor: pointer;
      height: 36px;
      color: #1F1F1F;
      font-size: 16px;
      font-weight: 600;
      line-height: 36px;
      display: flex;
      align-items: center;

    }
  }

  .courseListWrap {
    min-height: calc(100vh - 340px);
    margin: 0 auto;
    width: 942px;
    .wrapbanners{
      margin-top: 34px;
      position: relative;
      z-index: 9;
      display: flex;
      align-items: center;
      gap: 14px;
      .rightGuangGao{
        display: flex;
        flex-direction: column;
        gap: 16px;
        img{
          width: 289px;
          height: 110px;
        }
      }
      .banner {
        width: 639px;
        height: 237px;
        border-radius: 0 12px 12px 0;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0 12px 12px 0;

        }
      }
    }


    .pagination {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }
  }

  .curriculumList {
    position: relative;
    z-index: 9;
    min-height: 400px;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    align-items: center;

    .item {
      width: 304.67px;
      height: 294px;
      border-radius: 8px;
      border: 1px solid #FFFFFF;
      background: #FFFFFF;
      overflow: hidden;
    }
  }

  .searchs {
    //padding-top: 20px;
    margin-top: 90px;
    //background: #ffffff;
    //height: 220px;
    position: relative;
    z-index: 9;

    .searchsWrap {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      justify-content: center;

      .filterWrap {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        .item {
          display: flex;
          align-items: center;
          gap: 2px;

          .title {
            color: rgba($color: #000000, $alpha: 0.85);
            font-size: 14px;
            font-weight: 400;
          }

          .list {
            display: flex;
            align-items: center;
            gap: 16px;

            .current {
              background: #3A76FF;
              color: #ffffff !important;
            }

            .its {
              padding: 9px 16px;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: rgba($color: #000000, $alpha: 0.65);
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
